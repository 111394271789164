import React from "react"
import s from "./HeroSection.module.scss"

const HeroSection = () => {
    const info = [
        {
            id: 1,
            label: "Legal Name",
            value: "PAPEL PAYMENT SERVICES PROVIDER L.L.C",
        },
        { id: 2, label: "Registration No", value: "936139" },
        { id: 3, label: "Registered Authority", value: "Dubai Department of Economy and Tourism" },
        {
            id: 4,
            label: "Registered Address",
            value: "A601, Opus Tower, Business Bay, Dubai, United Arab Emirates.",
        },
    ]

    return (
        <div className={s.container}>
            <div className={s.wrapper}>
                <div className={s.titleWrapper}>
                    <div className={s.logo}>
                        <img src="/images/logo.svg" alt="papel logo" />
                    </div>
                    <div className={s.title}>
                        <p>The Ultimate Payment Solutions.</p>
                        <h1>Soon in the UAE!</h1>
                    </div>
                </div>
                <div className={s.info}>
                    {info.map(el => (
                        <div className={s.infoItem} key={el.id}>
                            <p className={s.label}>{el.label}</p>
                            <p className={s.value}>{el.value}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className={s.background} />
        </div>
    )
}

export default HeroSection
